import React from 'react';

import ModalBoxes from '../../../../../common/feedback/ModalBoxes/ModalBoxes';
import Button from '../../../../../common/general/Button';
import ButtonGroup from '../../../../../common/general/ButtonGroup';
import NotificationManager from '../../../../../common/notifications/NotificationManager';
import {
  PATIENT_REIMBURSEMENT_SENT_TO_VENDOR,
  PATIENT_STIPEND_SENT_TO_VENDOR
} from '../../../../../constants/notificationMessages';
import { eventTypeLabel } from '../../../setup/Budget/BudgetDetails/budgetConstant';

import { getActualPaymentTypeByElligoEventType } from './LadgerPatientMenu/PatientLedgerMenu';

import './LedgerTableStatusChangeModal.scss';

export default function LedgerTablePaymentReprocessingModal({ modalBox, onConfirm, eventId, eventType }) {
  const paymentType = getActualPaymentTypeByElligoEventType(eventType);
  return (
    <>
      <ModalBoxes.Header>Process Patient {paymentType}</ModalBoxes.Header>
      <ModalBoxes.Body>
        <p>Are you sure you want to submit this patient {paymentType.toLowerCase()} to the vendor for processing?</p>
      </ModalBoxes.Body>

      <ModalBoxes.Footer>
        <ButtonGroup>
          <Button priority="medium" onClick={modalBox.close}>
            No
          </Button>
          <Button
            onClick={() =>
              onConfirm(eventId)
                .then(
                  () => {
                    if (eventTypeLabel.PATIENT_REIMBURSEMENT === eventType) {
                      NotificationManager.success(PATIENT_REIMBURSEMENT_SENT_TO_VENDOR);
                    } else if (eventTypeLabel.ITEM_GROUP_PATIENT_STIPEND === eventType) {
                      NotificationManager.success(PATIENT_STIPEND_SENT_TO_VENDOR);
                    }
                  },
                  err => {
                    NotificationManager.error(err.response.data.message);
                  }
                )
                .finally(function() {
                  modalBox.close();
                })
            }
          >
            Yes
          </Button>
        </ButtonGroup>
      </ModalBoxes.Footer>
    </>
  );
}

LedgerTablePaymentReprocessingModal.className = 'enter-revenue-dates-modal';
LedgerTablePaymentReprocessingModal.size = 'w650';
